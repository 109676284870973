import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../elements/logo/Logo";
import logoLight from "../../assets/images/logo/1200x300-black.png";
import logoDark from "../../assets/images/logo/logo_white.png";
import { MdOutlineEmail } from "react-icons/md";
import {
  FiFacebook,
  FiInstagram,
  FiLinkedin,
  FiPhone,
  FiYoutube,
} from "react-icons/fi";
import { CiLocationOn } from "react-icons/ci";
function FooterHomeEight() {
  return (
    <>
      <section className="mgga-footer-area mgga-footer-about-area mgga-footer-8-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="footer-about-widget text-center">
                <div
                  className="logo"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Logo image={logoDark} image2={logoLight} />
                </div>
                <p>For Lifetime Smiles </p>
                <br />
                <div className="social-icon-wrapper mb--10">
                  <ul className="social-icon social-default icon-naked">
                    <li>
                      <a
                        href="https://instagram.com/megagenamerica"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="megagen america instagram"
                      >
                        <FiInstagram />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://facebook.com/megagenamerica"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="megagen america facebook"
                      >
                        <FiFacebook />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/megagenamerica"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="megagen america linkedin"
                      >
                        <FiLinkedin />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/@megagentv"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="megagen america youtube"
                      >
                        <FiYoutube />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">Products</h4>
                <ul>
                  <li>
                    <Link to="/products/implant-systems">Implant System</Link>
                  </li>
                  <li>
                    <Link to="/products/kits">Kits</Link>
                  </li>
                  <li>
                    <a href="/products/equipment">Equipment</a>
                  </li>
                  <li>
                    <Link to="/products/regenerative/igen">Regenerative</Link>
                  </li>
                </ul>
              </div>
            </div>{" "}
            <div className="col-lg-2 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">Solutions</h4>
                <ul>
                  {/* <li>
                    <Link to="/digital-solutions">Digital Solutions</Link>
                  </li> */}
                  <li>
                    <a href="/immediate-molar">Immediate Molar</a>
                  </li>
                  {/* <li>
                    <a href="/accelerated-loading">Accelerated Loading</a>
                  </li>
                  <li>
                    <a href="/r2-full-arch">R2 Full Arch</a>
                  </li> */}
                  <li>
                    <a href="https://www.minecamerica.com">Education</a>
                  </li>
                </ul>
              </div>
            </div>{" "}
            <div className="col-lg-2 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">Company</h4>
                <ul>
                  <li>
                    <Link to="/salesrep">Sales Directory</Link>
                  </li>
                  <li>
                    <Link to="/downloads">Downloads</Link>
                  </li>
                  <li>
                    <a href="/manufacturing">Manufacturing</a>
                  </li>
                  <li>
                    <Link to="/careers">Careers</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget-info">
                <h4 className="title">Contact Us</h4>
                <ul>
                  <li>
                    <a href="mailto:info@megagenamerica.com">
                      <i className="fal fa-envelope">
                        {" "}
                        <MdOutlineEmail />
                      </i>{" "}
                      info@megagenamerica.com
                    </a>
                  </li>
                  <li>
                    <a href="tel:8442885425">
                      <i className="fal fa-phone">
                        <FiPhone />
                      </i>
                      +(844) 288-5425
                    </a>
                  </li>
                  <li>
                    <a href="https://maps.app.goo.gl/R4aJ9daWDzAWZ9xh6">
                      <i className="fal fa-home">
                        <CiLocationOn />
                      </i>{" "}
                      HQ: 39-40 Broadway Fair Lawn, NJ 07410
                    </a>
                  </li>
                  <li>
                    <a href="https://maps.app.goo.gl/AP7YkEw9TvReCUAW7">
                      <i className="fal fa-home">
                        <CiLocationOn />
                      </i>{" "}
                      EDU Center: 909 Lake Carolyn Pkwy #1800 Irving, TX 75039
                    </a>
                  </li>{" "}
                  <li>
                    <a href=" https://maps.app.goo.gl/MMJtthZmDufwRgWL9">
                      <i className="fal fa-home">
                        <CiLocationOn />
                      </i>{" "}
                      Logistics: 55 Bergenline Ave. Westwood, NJ 07675
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FooterHomeEight;
