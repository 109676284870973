import React from "react";
import Layout from "../common/Layout";
import GoogleTag from "../elements/pageSections/googletag/GoogleTag";
import MetaData from "../elements/pageSections/helmet/Helmet";
import SinusTabNewTest from "../elements/tab/SinusTabNewTest";
import BannerEl from "../elements/pageSections/banner/BannerEl";
const BannerData = [
  {
    image: "",
  },
];
const SinusKitsTest = () => {
  const loaded = "/images/img/Kits/SinusKits/25_MICA__MILA_Kit_0103.webp";
  const description =
    "Choose precision in sinus lifts with MegaGen's kits - MICA for Crestal, MILA for Lateral, and Sinus Combination for ultimate flexibility in approach.";
  const keywords =
    "MegaGen Sinus Lift Kits, MICA Kit, Crestal Approach, MILA Kit, Lateral Approach, Sinus Combination, Sinus Lift Procedures, Dental Surgery Tools, Implantology Kits, Surgical Flexibility, MegaGen, MegaGen America";
  const title = "Sinus Elevation Kits - MegaGen America";
  const link = "https://megagenamerica.com/products/kits/sinus-kits";
  return (
    <>
      <GoogleTag />
      <MetaData
        description={description}
        title={title}
        keywords={keywords}
        link={link}
      />
      <main className="page-wrapper">
        <Layout>
          {/* Start Slider Area  */}
          <BannerEl loaded={loaded} bannerData={BannerData} />
          <div className="main-content mt--30">
            <div className="container">
              <SinusTabNewTest />
            </div>
          </div>
        </Layout>
      </main>
    </>
  );
};

export default SinusKitsTest;
