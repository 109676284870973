import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <ul className="mainmenu">
      <li className="with-megamenu">
        <Link to="#" style={{ fontSize: "18px", fontWeight: "500" }}>
          Products
        </Link>
        <div className="megamenu">
          <div className="wrapper">
            <div className="row row--0">
              <div className="col-xl-3 single-mega-item">
                <ul className="mega-menu-item">
                  <li id="future-dropdown">
                    <Link
                      style={{ fontSize: "18px", fontWeight: "600" }}
                      to="/products/implant-systems"
                    >
                      Implant Systems
                    </Link>
                    <ul className="submenu">
                      <li>
                        <Link
                          to="/products/implant-systems/anyridge"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          AnyRidge
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/implant-systems/anyridge-incisor"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          ARi&reg;{" "}
                          <sup style={{ fontSize: "10px", color: "gold" }}>
                            &nbsp;&nbsp;NEW
                          </sup>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/implant-systems/bluediamond"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          BLUEDIAMOND
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/implant-systems/anyone"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          AnyOne
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/implant-systems/advanced-intermezzo"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Advanced Intermezzo
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/products/implant-systems/mini"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          MiNi
                        </Link>
                      </li> */}
                    </ul>
                  </li>
                  <li id="future-dropdown">
                    <Link
                      style={{ fontSize: "18px", fontWeight: "600" }}
                      to="/products/prosthetics/anatomic-healing-abutment"
                    >
                      Prosthetics
                    </Link>
                    <ul className="submenu">
                      <li>
                        <Link
                          style={{ fontSize: "17px", fontWeight: "500" }}
                          to="/products/prosthetics/anatomic-healing-abutment"
                        >
                          Anatomic Healing Abutment
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col-xl-3 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <Link
                      to="/products/kits"
                      style={{ fontSize: "18px", fontWeight: "600" }}
                    >
                      MegaGen Kits
                    </Link>
                    <ul className="submenu">
                      <li>
                        <Link
                          to="/products/kits/ar-surgical-kit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          AnyRidge Surgical Kit
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/arI-surgical-kit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          ARi&reg; Surgical Kit{" "}
                          <sup style={{ fontSize: "10px", color: "gold" }}>
                            &nbsp;&nbsp;NEW
                          </sup>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/bd-surgical-kit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          BLUEDIAMOND Surgical Kit
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/ao-surgical-kit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          AnyOne Surgical Kit
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/bp-kits"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Bone Profiler Kits
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/prosthetic-kits"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Prosthetic Kits
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/ai-kit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Advanced Intermezzo Kit
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/r2-kits"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Guided Kits
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/pet-kit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Partial Extraction Kit
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/sinus-kits"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Sinus Elevation Kits{" "}
                          <sup style={{ fontSize: "10px", color: "gold" }}>
                            &nbsp;&nbsp;NEW
                          </sup>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/meggyver-kit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          MegGyver Kit{" "}
                          <sup style={{ fontSize: "10px", color: "gold" }}>
                            &nbsp;&nbsp;NEW
                          </sup>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/bonex-kit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          BonEx Kit
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col-xl-3 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <Link
                      to="/products/equipment"
                      style={{ fontSize: "18px", fontWeight: "600" }}
                    >
                      Equipment
                    </Link>
                    <ul className="submenu">
                      <li>
                        <Link
                          to="/products/equipment/n2-unit-chair"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          N2 Unit Chair{" "}
                          <sup style={{ fontSize: "10px", color: "red" }}>
                            &nbsp;&nbsp; HOT
                          </sup>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/equipment/r2-studio-q"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          R2 Studio Q - CBCT
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/equipment/r2i3"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          R2i3 - IOS{" "}
                          <sup style={{ fontSize: "10px", color: "gold" }}>
                            &nbsp;&nbsp;NEW
                          </sup>{" "}
                          <sup style={{ fontSize: "10px", color: "red" }}>
                            &nbsp;&nbsp; HOT
                          </sup>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/equipment/medit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          MEDIT - IOS
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/equipment/meg-engine"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          MEG Engine
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/equipment/plasmax-motion"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Plasma X Motion
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/equipment/mega-isq-ii"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          MEGA ISQ II
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/equipment/meg-torq"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          MEG TorQ
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* <li>
                    <Link
                      to="#"
                      style={{ fontSize: "18px", fontWeight: "600" }}
                    >
                      Shining3D
                    </Link>
                    <ul className="submenu">
                      <li>
                        <Link
                          to="/shining3d/scanner"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Aoralscan Elite & MetiSmile
                          <sup style={{ fontSize: "10px", color: "red" }}>
                            &nbsp;&nbsp; HOT
                          </sup>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/shining3d/printers"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          3D Printers
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/shining3d/fabwash"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          FabWash
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/shining3d/fabcure-2"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          FabCure 2
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </div>
              <div className="col-xl-3 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <Link
                      to="/products/regenerative/igen"
                      style={{ fontSize: "18px", fontWeight: "600" }}
                    >
                      Regenerative
                    </Link>
                    <ul className="submenu">
                      <li>
                        <Link
                          to="/products/regenerative/igen"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          iGen Membrane
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li className="has-dropdown">
        <Link to="#" style={{ fontSize: "18px", fontWeight: "500" }}>
          Digital
        </Link>
        <ul className="submenu">
          <li>
            <Link
              to="/digital/r2gate"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              R2GATE
            </Link>
          </li>
          <li>
            <Link
              to="/digital/r2dod"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              R2DOD
            </Link>
          </li>
          <li>
            <Link
              to="/digital/facegide"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              FACEGIDE
            </Link>
          </li>
          <li>
            <Link
              to="/products/kits/r2-kits"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              Guided Kits
            </Link>
          </li>
          <li>
            <Link
              to="/digital/equipment"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              Digital Equipment
            </Link>
          </li>
          <li>
            <Link
              to="/digital/prosthetics"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              Digital Prosthetics
            </Link>
          </li>
          <li>
            <a
              href="https://www.megagendigital.com"
              target="_blank"
              rel="noreferrer"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              Digital Library
            </a>
          </li>
        </ul>
      </li>

      <li className="has-dropdown">
        <Link to="#" style={{ fontSize: "18px", fontWeight: "500" }}>
          Company
        </Link>
        <ul className="submenu">
          <li>
            <Link
              to="/about-us"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              to="/manufacturing"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              Manufacturing
            </Link>
          </li>
          <li>
            <Link to="/safety" style={{ fontSize: "17px", fontWeight: "500" }}>
              Quality & Safety
            </Link>
          </li>
          <li>
            <Link to="/minec" style={{ fontSize: "17px", fontWeight: "500" }}>
              Education
            </Link>
          </li>
          <li>
            <Link to="/careers" style={{ fontSize: "17px", fontWeight: "500" }}>
              Careers
            </Link>
          </li>
        </ul>
      </li>

      <li className="has-dropdown">
        <Link to="#" style={{ fontSize: "18px", fontWeight: "500" }}>
          Patients
        </Link>
        <ul className="submenu">
          <li>
            <Link
              to="/why-megagen"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              Why MegaGen?
            </Link>
          </li>
          <li>
            <Link
              to="/one-day-implants"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              One-Day Implants
            </Link>
          </li>
          <li>
            <Link
              to="/implant-treatment"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              Implant Treatment
            </Link>
          </li>
          <li>
            <Link
              to="/treatment-benefits"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              {" "}
              Treatment Benefits
            </Link>
          </li>
          <li>
            <Link to="/faq" style={{ fontSize: "17px", fontWeight: "500" }}>
              FAQ
            </Link>
          </li>
        </ul>
      </li>

      <li className="has-dropdown">
        <Link to="#" style={{ fontSize: "18px", fontWeight: "500" }}>
          Contact
        </Link>
        <ul className="submenu">
          <li>
            <Link to="/contact" style={{ fontSize: "17px", fontWeight: "500" }}>
              Contact Us
            </Link>
          </li>
          <li>
            <Link
              to="/salesrep"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              Sales Directory{" "}
            </Link>
          </li>
          <li>
            <a
              href="tel:+8442885425"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              (844) 288 5425
            </a>
          </li>
        </ul>
      </li>
      <li className="has-dropdown">
        <Link to="/downloads" style={{ fontSize: "18px", fontWeight: "500" }}>
          Downloads
        </Link>
      </li>
    </ul>
  );
};
export default Nav;
