import React from "react";
import { AiOutlineScan } from "react-icons/ai";
import { GiTooth } from "react-icons/gi";
import { HiOutlineDesktopComputer } from "react-icons/hi";
function DigitalSolCards({ style }) {
  return (
    <>
      <section
        className="mgga-counter-area pt--90 pb--190 mb--120"
        id="counter"
        style={style}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 mb--50">
              <div
                className="mgga-single-counter mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="counter-content">
                  <div className="icon" style={{ fontSize: "40px" }}>
                    <AiOutlineScan />
                  </div>
                  <h3 className="title" style={{ fontSize: "30px" }}>
                    Data <br /> Acquisition
                  </h3>
                  <button
                    className="btn-default btn-small"
                    onClick={() => {
                      const element = document.getElementById("data");
                      element?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    View Products
                  </button>{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb--50">
              <div
                className="mgga-single-counter mt-30 item-2 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="counter-content">
                  <div className="icon" style={{ fontSize: "40px" }}>
                    <HiOutlineDesktopComputer />
                  </div>
                  <h3 className="title" style={{ fontSize: "30px" }}>
                    Planning & <br /> Design
                  </h3>
                  <button
                    className="btn-default btn-small"
                    onClick={() => {
                      const element = document.getElementById("planning");
                      element?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    View Products
                  </button>{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb--50">
              <div
                className="mgga-single-counter mt-30 item-3 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="600ms"
              >
                <div className="counter-content">
                  <div className="icon" style={{ fontSize: "40px" }}>
                    <img
                      style={{
                        height: "60px",
                        width: "60px",
                        display: "inline",
                      }}
                      src="/images/img/Digital/DigitalCard/3dprint.webp"
                      alt="3d printer"
                    />
                  </div>
                  <h3 className="title" style={{ fontSize: "30px" }}>
                    Printing
                    <br />
                    &nbsp;
                  </h3>

                  <button
                    className="btn-default btn-small"
                    onClick={() => {
                      const element = document.getElementById("printing");
                      element?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    View Products
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb--50">
              <div
                className="mgga-single-counter mt-30 item-4 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="800ms"
              >
                <div className="counter-content">
                  <div className="icon" style={{ fontSize: "40px" }}>
                    <GiTooth />
                  </div>
                  <h3 className="title" style={{ fontSize: "30px" }}>
                    Surgical <br />
                    Solutions
                  </h3>
                  <button
                    className="btn-default btn-small"
                    onClick={() => {
                      const element = document.getElementById("surgical");
                      element?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    View Products
                  </button>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DigitalSolCards;
