import "../assets/css/style.css";
import React from "react";
import ItemListings from "../elements/itemlisting/ItemListings";
import TestLayout from "../common/TestLayout";
import BreadcrumbNew from "../elements/breadcrumb/BreadcrumbNew";
import GoogleTag from "../elements/pageSections/googletag/GoogleTag";
import MetaData from "../elements/pageSections/helmet/Helmet";
const listingData = [
  [
    {
      name: "Why Do Dentists Trust MegaGen?",
      img: "https://placehold.co/300x300",
      alt: "Why MegaGen",
      href: "/why-megagen",
    },
    {
      name: "How Long Is Implant Treatment?",
      img: "https://placehold.co/300x300",
      alt: "one day implant",
      href: "/one-day-implants",
    },
    {
      name: "Benefits of Implant Treatment",
      img: "https://placehold.co/300x300",
      alt: "treatment benefits",
      href: "/implant-treatment",
    },
    {
      name: "FAQ",
      img: "https://placehold.co/300x300",
      alt: "faq",
      href: "/faq",
    },
  ],
];
const PatientsFAQ = () => {
  const description =
    "Explore MegaGen's Patient Care Hub: Your go-to for FAQs and aftercare details. Empowering patients with knowledge for a confident journey.";
  const keywords =
    "MegaGen, MegaGen America, MegaGen Patient Care Hub, Patient FAQs, Aftercare Information, Dental Patient Resources, MegaGen Dental Care, Patient Education, Confident Dental Journey, MegaGen FAQs, Aftercare Support, Dental Information Hub";
  const title = "FAQ & Aftercare - MegaGen America";
  const link = "https://megagenamerica.com/faq";
  return (
    <>
      {" "}
      <GoogleTag />
      <MetaData
        description={description}
        title={title}
        keywords={keywords}
        link={link}
      />
      <TestLayout>
        <BreadcrumbNew
          title="Patient Resources"
          breadcrumb={[
            { link: "/", title: "Home" },
            { link: "/patients", title: "Patients" },
          ]}
        />
        <br />
        <ItemListings
          category="Frequently Searched Topics"
          prodClass="data"
          description="Discover answers to common questions about dental implants and procedures."
          data={listingData[0]}
          bgColor={"#"}
        />
      </TestLayout>
    </>
  );
};

export default PatientsFAQ;
