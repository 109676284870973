import React from "react";
import { Link } from "react-router-dom";

function BreadcrumbNew({ title, breadcrumb = [] }) {
  return (
    <>
      <div
        className="mgga-page-title-area"
        style={{
          backgroundImage:
            "url('/images/img/Implant/Systems/24_Implant_Systems_0222.webp')",
          transform: "scaleX(-1)",
        }}
      >
        <div className="container" style={{ transform: "scaleX(-1)" }}>
          <div className="row">
            <div className="col-lg-12">
              <div className="mgga-page-title-item">
                <h3 className="title" style={{ fontSize: "50px" }}>
                  {title}
                </h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    {breadcrumb.map((value) => (
                      <li
                        key={Math.random()}
                        className="breadcrumb-item"
                        style={{ fontSize: "20px" }}
                      >
                        <Link to={value.link}>{value.title}</Link>
                      </li>
                    ))}
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BreadcrumbNew;
